

























import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";
import {
  PunishActionEditorButtonsKey,
  PunishActionEditorPlaceholdersKey,
  PunishActionEditorPrefix
} from "@/includes/logic/Modules/di";

import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import {
  PlaceholdersOnes,
  PlaceholdersStack
} from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import {
  IMessageSettings
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Body/logic/types";
import {
  MessageMedia
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types";
import {
  MessageButtons
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Component, Inject, Mixins, Prop, VModel } from 'vue-property-decorator';
import { instanceToPlain, plainToInstance } from "class-transformer";

@Component({
  components: {
    MultiMessageEditorWithMediaInput
  }
})
export default class PunishMessage extends Mixins(InputSetups, Placeholders, ModelSetter) {
  @VModel() punishMessageModel!: Array<IMessageSettings &  MessageMedia & MessageButtons>

  @Prop() validationRules!: string

  @Inject(PunishActionEditorPlaceholdersKey) placeholders!: PlaceholdersOnes | PlaceholdersStack

  @Inject(PunishActionEditorButtonsKey) buttons!: PlaceholdersOnes | PlaceholdersStack

  @Inject(PunishActionEditorPrefix) prefix!: string

  get punishMessage() {
    return plainToInstance(MessageEditorWithMediaData, this.punishMessageModel)
  }

  set punishMessage(value: Array<MessageEditorWithMediaData>) {
    this.punishMessageModel = instanceToPlain(value) as Array<IMessageSettings &  MessageMedia & MessageButtons>
  }

  get buttonsOrBase() {
    if (this.buttons) {
      return this.buttons
    }

    return this.newFilterEditorButtons
  }

  get placeholdersOrBase() {
    if (this.placeholders) {
      return this.placeholders
    }

    return this.PUNISH_MESSAGE_PLACEHOLDERS
  }
}
