var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
    'func': _vm.newConfigInputSetup,
    'args': {
      'model': this,
      'key': 'punishTime',
      'settingWrapper': {
        value: 0,
        hasFieldButton: true,
        customiseValue: _vm.StepUnit.Hour
      }
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }